<template>
  <div :class="$style.main">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
      >
        Сохранить контент
      </el-button>
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="7rem"
      :class="$style.form"
    >
      <h2 :class="$style.name">Главная страница Addwine</h2>
      <el-form-item label="SEO-тексты">
        <SeoTextsBlock :seoTexts="form.seoTexts" @getSeo="getSeoTexts" />
      </el-form-item>

      <el-form-item label="SEO-FAQ">
        <SeoFaqsBlock :seoFAQs="form.seoFaqs" @getSeo="getSeoFaqs" />
      </el-form-item>

      <div :class="$style.block">
        <h3>Главный баннер</h3>
        <Container
          tag="div"
          :class="$style.body"
          lock-axis="y"
          :non-drag-area-selector="'.drag-disabled'"
          @drop="onDrop($event, 'mainBannersSection')"
        >
          <Draggable
            tag="div"
            v-for="(banner, index) in form.mainBannersSection"
            :key="index"
            :class="$style.row"
          >
            <Icon name="draggable" :class="$style.icon" />
            <div :class="$style.content">
              <el-form-item
                label="Заголовок"
                :prop="'mainBannersSection.' + index + '.title'"
                :class="$style.item"
                class="drag-disabled"
                :rules="rules.mainBannersSection.title"
              >
                <el-input v-model="banner.title" />
              </el-form-item>
              <el-form-item
                label="Описание"
                :prop="'mainBannersSection.' + index + '.description'"
                :class="$style.item"
                class="drag-disabled"
                :rules="rules.mainBannersSection.description"
              >
                <el-input v-model="banner.description" />
              </el-form-item>
              <el-form-item
                label="Ссылка"
                :prop="'mainBannersSection.' + index + '.link'"
                :class="$style.item"
                class="drag-disabled"
                :rules="rules.mainBannersSection.link"
              >
                <el-input v-model="banner.link" />
              </el-form-item>
              <el-form-item
                label="Фото"
                :prop="'mainBannersSection.' + index + '.image'"
                :class="$style.uploader"
                :rules="rules.mainBannersSection.image"
              >
                <Uploader
                  without-caption
                  :limit="1"
                  :files="banner.image ? [{ original: banner.image }] : []"
                  @upload="uploadImages($event, 'mainBannersSection', index)"
                />
              </el-form-item>
            </div>
            <el-button
              type="danger"
              plain
              circle
              icon="el-icon-delete"
              :class="$style.remove"
              @click="removeItem(index, 'mainBannersSection')"
            />
          </Draggable>
        </Container>
        <el-button
          size="small"
          type="primary"
          @click="addItem('mainBannersSection')"
          >Добавить баннер</el-button
        >
      </div>
      <div :class="$style.delivery">
        <h3>Блок доставки</h3>
        <div :class="$style.body">
          <el-form-item
            label="Заголовок"
            :prop="'deliveryBlockSection.title'"
            :class="$style.item"
          >
            <el-input v-model="form.deliveryBlockSection.title" />
          </el-form-item>
          <el-form-item
            label="Иконка"
            label-width="7rem"
            :prop="'deliveryBlockSection.iconName'"
          >
            <el-select
              v-model="form.deliveryBlockSection.iconName"
              filterable
              placeholder="Выберите иконку"
              :popper-class="$style.iconOptions"
            >
              <el-option
                v-for="(item, index) in iconOptions"
                :key="index"
                :label="item"
                :value="item"
              >
                <Icon :name="item" />
                {{ item }}
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            label="Ссылка"
            :prop="'deliveryBlockSection.link'"
            :class="$style.item"
          >
            <el-input v-model="form.deliveryBlockSection.link" />
          </el-form-item>
          <el-form-item
            label="Кнопка"
            :prop="'deliveryBlockSection.buttonText'"
            :class="$style.item"
          >
            <el-input v-model="form.deliveryBlockSection.buttonText" />
          </el-form-item>
        </div>
      </div>
      <div :class="$style.block">
        <h3>Дополнительные баннеры</h3>
        <Container
          tag="div"
          :class="$style.body"
          lock-axis="y"
          :non-drag-area-selector="'.drag-disabled'"
          @drop="onDrop($event, 'secondaryBannersSection')"
        >
          <Draggable
            tag="div"
            v-for="(banner, index) in form.secondaryBannersSection"
            :key="index"
            :class="$style.row"
          >
            <Icon name="draggable" :class="$style.icon" />
            <div :class="$style.content">
              <el-form-item
                label="Заголовок"
                :prop="'secondaryBannersSection.' + index + '.title'"
                :class="$style.item"
                class="drag-disabled"
                :rules="rules.secondaryBannersSection.title"
              >
                <el-input v-model="banner.title" />
              </el-form-item>
              <el-form-item
                label="Ссылка"
                :prop="'secondaryBannersSection.' + index + '.link'"
                :class="$style.item"
                class="drag-disabled"
                :rules="rules.secondaryBannersSection.link"
              >
                <el-input v-model="banner.link" />
              </el-form-item>
              <el-form-item
                label="Фото"
                :prop="'secondaryBannersSection.' + index + '.image'"
                :class="$style.uploader"
                :rules="rules.secondaryBannersSection.image"
              >
                <Uploader
                  without-caption
                  :limit="1"
                  :files="banner.image ? [{ original: banner.image }] : []"
                  @upload="
                    uploadImages($event, 'secondaryBannersSection', index)
                  "
                />
              </el-form-item>
            </div>
          </Draggable>
        </Container>
      </div>
      <div :class="$style.block">
        <h3>Блок Особенности</h3>
        <div :class="$style.body">
          <Container
            tag="div"
            :class="$style.body"
            lock-axis="y"
            :non-drag-area-selector="'.drag-disabled'"
            @drop="onDrop($event, 'advantagesSection')"
          >
            <Draggable
              tag="div"
              v-for="(advantage, index) in form.advantagesSection"
              :key="index"
              :class="$style.row"
            >
              <Icon name="draggable" :class="$style.icon" />
              <div :class="$style.content">
                <el-form-item
                  label="Описание"
                  :prop="'advantagesSection.' + index + '.text'"
                  :class="$style.item"
                  class="drag-disabled"
                  :rules="rules.advantagesSection.text"
                >
                  <el-input v-model="advantage.text" />
                </el-form-item>
                <el-form-item label="Иконка" label-width="7rem">
                  <el-select
                    v-model="form.advantagesSection[index].iconName"
                    filterable
                    placeholder="Выберите иконку"
                    :popper-class="$style.iconOptions"
                  >
                    <el-option
                      v-for="(item, index) in iconOptions"
                      :key="index"
                      :label="item"
                      :value="item"
                    >
                      <Icon :name="item" />
                      {{ item }}
                    </el-option>
                  </el-select>
                </el-form-item>
              </div>
              <el-button
                type="danger"
                plain
                circle
                icon="el-icon-delete"
                :class="$style.remove"
                @click="removeItem(index, 'advantagesSection')"
              />
            </Draggable>
          </Container>
        </div>
        <el-button
          size="small"
          type="primary"
          @click="addItem('advantagesSection')"
          >Добавить</el-button
        >
      </div>
      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import Icon from '@/components/atoms/Icon.vue'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import delivery from '@/delivery'
import SeoBlock from '@/components/moleculs/SEO.vue'
import regexp from '@/helpers/regexp.js'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'
export default {
  components: { Icon, Uploader, SeoBlock, SeoTextsBlock, SeoFaqsBlock },
  data() {
    return {
      iconOptions: [
        'quality',
        'official',
        'consultant',
        'certificate',
        'delivery-car',
      ],
      form: {
        seoTexts: [],
        seoFaqs: [],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
        deliveryBlockSection: {
          title: '',
          iconName: '',
          link: '',
          buttonText: '',
        },
      },
      rules: {
        mainBannersSection: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          link: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
            {
              pattern: regexp.url,
              message: 'Пожалуйста, введите валидный url',
              trigger: 'change',
            },
          ],
          image: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        secondaryBannersSection: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          link: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          image: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        deliveryBlockSection: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          link: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          iconName: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          buttonText: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        advantagesSection: {
          text: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          iconName: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
        },
        seo: {
          title: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          description: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogTitle: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogDescription: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'blur',
            },
          ],
          ogImage: [
            {
              required: true,
              message: 'Пожалуйста, заполните поле',
              trigger: 'change',
            },
          ],
        },
      },
    }
  },

  async created() {
    await this.getContent()
  },
  methods: {
    getSeoTexts(seoTexts) {
      this.form.seoTexts = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqs = seoFaqs
    },
    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get('', 'addwine')
      loading.close()

      if (error) return

      this.form = value.content
      this.form.seoTexts =
        this.form?.seoTexts?.map((seoText) => seoText.id) ?? []
      this.form.seoFaqs = this.form?.seoFaqs?.map((seoFaq) => seoFaq.id) ?? []
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const seoTexts = this.form.seoTexts?.map((seoText) => ({
            id: seoText,
          }))

          const seoFaqs = this.form.seoFaqs?.map((seoFaq) => ({
            id: seoFaq,
          }))

          const data = {
            ...this.form,
            seoTexts,
            seoFaqs,
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            '',
            'addwine',
            data,
          )
          loading.close()

          if (result.error) {
            this.$message({
              message: 'Ошибка редактирования страницы',
              type: 'error',
            })
            return
          }

          this.$message({
            message: 'Страница успешно отредактирована',
            type: 'success',
          })

          this.$router.push('/addwine/content/pages')
        }
      })
    },
    onDrop(dropResult, fieldName) {
      document.body.classList.remove('smooth-dnd-no-user-select')
      document.body.classList.remove('smooth-dnd-disable-touch-action')

      this.form[fieldName] = this.applyDrag(this.form[fieldName], dropResult)
    },
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    uploadImages(images, fieldName, index) {
      this.form[fieldName][index].image = images.length
        ? images[0].original
        : ''
    },
    addItem(fieldName) {
      switch (fieldName) {
        case 'advantagesSection': {
          this.form.advantagesSection.push({
            text: '',
            iconName: '',
          })
          break
        }
        case 'mainBannersSection': {
          this.form.mainBannersSection.push({
            title: '',
            description: '',
            link: '',
            image: '',
          })
          break
        }
      }
    },
    removeItem(index, fieldName) {
      this.form[fieldName].splice(index, 1)
    },
  },
}
</script>

<style lang="scss" module>
.iconOptions > div > div > ul {
  li {
    padding-top: 1rem;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      max-width: 5rem;
    }
  }
}
.main {
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .form {
    padding: 0 1.5rem;
    h2 {
      text-align: center;
      margin-bottom: 2rem;
    }
    .seoTexts {
      width: 95%;
    }
    .delivery {
      margin-top: 2rem;
      .body {
        margin-top: 1.5rem;
      }
    }
    .block {
      margin-top: 2rem;
      .body {
        margin-top: 1.5rem;
        .row {
          display: flex;
          align-items: center;
          &:not(:last-child) {
            border-bottom: 0.063rem solid $gray;
          }
          padding: 1rem 0;
          .content {
            margin: 0 1.5rem;
            width: 100%;
            & > div:last-child {
              margin-bottom: 0;
            }
          }
          .icon {
            width: 1.5rem;
            height: 1.5rem;
          }
        }
      }
    }
  }
}
</style>
